import React, { useEffect, useState } from 'react';
import { 
  Shield, 
  Package, 
  Zap, 
  CheckCircle, 
  ArrowRight, 
  ChevronRight,
  Code,
  Users,
  Globe,
  Heart,
  Workflow,
  Gauge,
  Lock,
  Rocket,
  Settings,
  GitBranch,
  FileText
} from 'lucide-react';
import logo from "../assets/logo_dark.png";
import vantaLogo from "../assets/vanta.png";
import firstRoundLogo from "../assets/firstround.png";
import dropboxLogo from "../assets/dropbox.svg";
import soc2Logo from "../assets/soc 2.png";
import iso27001Logo from "../assets/iso.png";
import { TextField } from "../components/TextField";
import { Button } from "../components/Button";
import axios from "axios";


import { API_URL } from "../config";

// Common colors and styles used throughout the component
const colors = {
  bg: {
    primary: "bg-[#0A1A35]", // Deep navy
    secondary: "bg-[#132B52]", // Rich navy
    card: "bg-[#132B52]/90",
    accent: "bg-[#34D399]", // Emerald green
    accent2: "bg-[#0EA5E9]", // Sky blue for subtle accents
    gradient: `
      bg-[linear-gradient(to_bottom,rgba(52,211,153,0.03)_0%,transparent_100%)]
      after:content-['']
      after:absolute
      after:inset-0
      after:pointer-events-none
      after:bg-[radial-gradient(circle_800px_at_100%_200px,rgba(14,165,233,0.05),transparent)]
      before:content-['']
      before:absolute
      before:inset-0
      before:pointer-events-none
      before:bg-[radial-gradient(circle_600px_at_0%_400px,rgba(52,211,153,0.03),transparent)]
      relative
    `
  },
  text: {
    primary: "text-slate-50",
    secondary: "text-slate-200",
    muted: "text-slate-300",
    dimmed: "text-slate-400",
    accent: "text-emerald-400",
    accent2: "text-sky-400",
    red: "text-rose-500"
  },
  border: {
    subtle: "border-[#1E3A64]",
    hover: "hover:border-emerald-500/20"
  }
} as const;

const styles = {
  container: `min-h-screen ${colors.bg.primary} ${colors.bg.gradient}`,
  maxWidthWrapper: "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8",
  card: `${colors.bg.card} backdrop-blur-sm p-8 rounded-2xl border ${colors.border.subtle} ${colors.border.hover} transition-all duration-300 hover:translate-y-[-2px] hover:bg-[#1a3156]/90 relative
    before:absolute before:inset-0 before:rounded-2xl before:p-[1px] before:bg-gradient-to-b before:from-[#1E3A64] before:to-transparent before:-z-10 before:pointer-events-none`,
  cardLarge: `${colors.bg.card} backdrop-blur-sm p-10 rounded-2xl border ${colors.border.subtle} ${colors.border.hover} transition-all duration-300 hover:translate-y-[-2px] hover:bg-[#1a3156]/90 relative
    before:absolute before:inset-0 before:rounded-2xl before:p-[1px] before:bg-gradient-to-b before:from-[#1E3A64] before:to-transparent before:-z-10 before:pointer-events-none`,
  iconWrapper: `bg-gradient-to-br from-emerald-500/10 to-sky-500/10 w-14 h-14 rounded-2xl flex items-center justify-center mb-6 border ${colors.border.subtle} relative
    before:absolute before:inset-0 before:rounded-2xl before:p-[1px] before:bg-gradient-to-b before:from-emerald-500/20 before:to-sky-500/20 before:-z-10 before:pointer-events-none`,
  icon: `w-7 h-7 ${colors.text.accent}`,
  heading: `text-2xl font-semibold mb-3 ${colors.text.primary} tracking-tight`,
  paragraph: colors.text.muted,
  checklistItem: "flex items-start gap-3",
  checkIcon: `w-5 h-5 ${colors.text.accent} flex-shrink-0`,
  link: `${colors.text.secondary} hover:text-emerald-400 transition-all duration-300`,
  button: {
    primary: `bg-gradient-to-r from-emerald-400 to-emerald-500 text-[#0A1A35] px-8 py-4 rounded-xl font-medium hover:from-emerald-500 hover:to-emerald-400 transition-all duration-300 hover:translate-y-[-2px] shadow-lg shadow-emerald-500/10 relative
      before:absolute before:inset-0 before:rounded-xl before:p-[1px] before:bg-gradient-to-b before:from-white/20 before:to-transparent before:-z-10`,
    secondary: `bg-white/[0.02] backdrop-blur-sm border ${colors.border.subtle} ${colors.text.secondary} px-8 py-4 rounded-xl font-medium hover:bg-white/[0.05] transition-all duration-300 hover:translate-y-[-2px] relative
      before:absolute before:inset-0 before:rounded-xl before:p-[1px] before:bg-gradient-to-b before:from-[#1E3A64] before:to-transparent before:-z-10`
  },
  header: `${colors.bg.primary}/95 backdrop-blur-xl border-b ${colors.border.subtle} sticky top-0 z-50`,
  sectionTitle: `text-4xl font-bold mb-8 max-w-4xl ${colors.text.primary} tracking-tight`,
  testimonialText: `text-lg ${colors.text.secondary} mb-4`,
  footer: `${colors.bg.primary} mt-32 border-t ${colors.border.subtle}`,
  counter: {
    wrapper: "flex justify-center items-center gap-16 mt-16 mb-12",
    item: "text-center",
    number: `text-5xl font-bold ${colors.text.accent} mb-3`,
    label: colors.text.secondary
  }
} as const;

interface AnimatedCounterProps {
  end: number;
  duration?: number;
}

// Animated counter component
const AnimatedCounter: React.FC<AnimatedCounterProps> = ({ end, duration = 2000 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime: number | null = null;
    const step = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      setCount(Math.floor(progress * end));
      
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    
    window.requestAnimationFrame(step);
  }, [end, duration]);

  return (
    <span>{count.toLocaleString()}</span>
  );
};

interface AnimatedRegistryProps {
  onHover: boolean;
}

const AnimatedRegistry: React.FC = () => {
  const [text, setText] = useState("");
  const [showCursor, setShowCursor] = useState(true);
  const [commandEntered, setCommandEntered] = useState(false);
  const command = "npm config set registry https://registry.syntra.dev";
  
  useEffect(() => {
    const animate = () => {
      setText("");
      setCommandEntered(false);
      
      let i = 0;
      const typeInterval = setInterval(() => {
        if (i <= command.length) {
          setText(command.slice(0, i));
          i++;
        } else {
          clearInterval(typeInterval);
          setTimeout(() => setCommandEntered(true), 300);
        }
      }, 50);

      // Reset after 5 seconds
      setTimeout(() => {
        setText("");
        setCommandEntered(false);
      }, 5000);

      return () => clearInterval(typeInterval);
    };
    
    animate(); // Initial animation
    const interval = setInterval(animate, 6000); // Loop every 6 seconds
    
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 800);
    
    return () => {
      clearInterval(interval);
      clearInterval(cursorInterval);
    };
  }, []);
  
  return (
    <div className="mt-4 bg-[#0B1221] p-3 rounded-lg border border-gray-800/60 h-40">
      {/* Command Input */}
      <div className="text-sm mb-6 font-mono h-8">
        <span className="text-gray-400">$ </span>
        <span className="text-emerald-200/90">{text}</span>
        <span className={`ml-0.5 text-emerald-200/90 ${showCursor ? 'opacity-100' : 'opacity-0'} transition-opacity duration-150`}>▋</span>
      </div>
      
      {/* Registry Diagram */}
      <div className="relative">
        <div className="flex items-center justify-between w-full">
          {/* NPM Registry */}
          <div className="flex flex-col items-center w-1/5">
            <div className="text-xs text-gray-500 mb-2">Registries</div>
            <div className="bg-gray-800/50 p-2 rounded border border-gray-800">
              <svg viewBox="0 0 24 24" className="w-5 h-5 text-red-400" fill="currentColor">
                <path d="M0 7.334v8h6.666v1.332H12v-1.332h12v-8H0zm6.666 6.664H5.334v-4H3.999v4H1.335V8.667h5.331v5.331zm4 0v1.336H8.001V8.667h5.334v5.332h-2.669v-.001zm12.001 0h-1.33v-4h-1.336v4h-1.335v-4h-1.33v4h-2.671V8.667h8.002v5.331zM10.665 10H12v2.667h-1.335V10z"/>
              </svg>
            </div>
          </div>

          <div className="flex items-center justify-center w-3/5 relative">
            {/* Initial Arrow */}
            <div className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ${
              commandEntered ? 'opacity-0' : 'opacity-100'
            }`}>
              <ArrowRight className="w-full h-5 text-gray-600" />
            </div>

            {/* Syntra with Arrows */}
            <div className={`absolute inset-0 flex items-center justify-between transition-all duration-300 ${
              commandEntered ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}>
              <div className="flex-1 flex justify-center">
                <ArrowRight className="w-5 h-5 text-emerald-400/40" />
              </div>
              <div className="flex flex-col items-center px-4">
                <div className="text-xs text-gray-500 mb-2">Syntra</div>
                <div className="bg-gray-800/50 p-2 rounded border border-gray-800">
                  <svg viewBox="0 0 24 24" className="w-5 h-5 text-emerald-400" fill="currentColor">
                    <path d="M12 2L2 7v10l10 5 10-5V7L12 2zm0 2.328L19.345 8 12 11.672 4.655 8 12 4.328zM4 9.199l7 3.5v7.102l-7-3.5V9.199zm9 10.602V12.7l7-3.5v7.102l-7 3.5z"/>
                  </svg>
                </div>
              </div>
              <div className="flex-1 flex justify-center">
                <ArrowRight className="w-5 h-5 text-emerald-400/40" />
              </div>
            </div>
          </div>

          {/* Your Codebase */}
          <div className="flex flex-col items-center w-1/5">
            <div className="text-xs text-gray-500 mb-2">Your code</div>
            <div className="bg-gray-800/50 p-2 rounded border border-gray-800">
              <svg viewBox="0 0 24 24" className="w-5 h-5 text-emerald-400" fill="currentColor">
                <path d="M12.89 3L14.85 3.4L11.11 21L9.15 20.6L12.89 3M19.59 12L16 8.41V5.58L22.42 12L16 18.41V15.58L19.59 12M4.41 12L8 15.58V18.41L1.58 12L8 5.58V8.41L4.41 12Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AnimatedPackageProps {
  onHover: boolean;
}

const AnimatedPackage: React.FC = () => {
  const [currentPackage, setCurrentPackage] = useState(0);
  const [showArrow, setShowArrow] = useState(false);
  const [showPatched, setShowPatched] = useState(false);
  
  const packages = [
    { name: "log4j@2.14", patched: "log4j@2.14-patched" },
    { name: "lodash@4.17", patched: "lodash@4.17-patched" },
    { name: "request@2.28", patched: "request@2.28-patched" }
  ];
  
  useEffect(() => {
    const cycle = () => {
      // Show arrow first
      setShowArrow(true);
      
      // Then show patched version
      setTimeout(() => {
        setShowPatched(true);
      }, 500);
      
      // Reset and move to next package
      setTimeout(() => {
        setShowArrow(false);
        setShowPatched(false);
        setTimeout(() => {
          setCurrentPackage(prev => (prev + 1) % packages.length);
        }, 500);
      }, 2500);
    };
    
    // Initial delay before starting
    setTimeout(cycle, 500);
    const interval = setInterval(cycle, 4000);
    return () => clearInterval(interval);
  }, []);
  
  const pkg = packages[currentPackage];
  
  return (
    <div className="mt-4 bg-[#0B1221] rounded-lg border border-gray-800/60 px-4 overflow-hidden h-40 flex items-center">
        <div className="flex items-center justify-center gap-4">
          {/* Original Package */}
          <div className="flex flex-col items-center gap-2 w-16 ml-4">
            <div className="bg-[#0D1526] p-2 rounded border border-gray-800/60">
              <Package className="w-5 h-5 text-red-400" />
            </div>
            <span className="text-xs font-mono text-red-200 truncate max-w-[120px]">
              {pkg.name}
            </span>
          </div>

          {/* Arrow and Patched Version Container */}
          <div className="flex items-center gap-2">
            {/* Arrow */}
            <div className={`transition-all duration-300 ${
              showArrow ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
            }`}>
              <ArrowRight className="w-4 h-4 text-emerald-400" />
            </div>

            {/* Patched Version */}
            <div className={`flex flex-col items-center gap-2 transition-all duration-300 min-w-0 ml-4 ${
              showPatched ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}>
              <div className="bg-[#0D1526] p-2 rounded border border-gray-800/60">
                <Package className="w-5 h-5 text-emerald-400" />
              </div>
              <span className="text-xs font-mono text-emerald-200 truncate max-w-[240px]">
                {pkg.patched}
              </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AnimatedScanProps {
  onHover: boolean;
}

const AnimatedScan: React.FC = () => {
  const [count, setCount] = useState(243);
  
  useEffect(() => {
    const animate = () => {
      setCount(243);
      const countInterval = setInterval(() => {
        setCount(prev => {
          if (prev <= 0) {
            clearInterval(countInterval);
            return 0;
          }
          return Math.max(0, prev - Math.ceil(prev / 10));
        });
      }, 100);
      
      // Reset after 4 seconds
      setTimeout(() => {
        clearInterval(countInterval);
      }, 4000);
    };
    
    animate(); // Initial animation
    const interval = setInterval(animate, 5000); // Loop every 5 seconds
    
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  return (
    <div className="mt-4 bg-[#0B1221] p-3 rounded-lg border border-gray-800/60 font-mono h-40">
        <div className="text-sm m-4">
          <span className="text-gray-400">$ npm audit</span>
          <br />
          <span className={count === 0 ? 'text-emerald-200/90' : 'text-red-300'}>
            {count === 0 ? '✓ 0 vulnerabilities found' : `found ${count} vulnerabilities`}
          </span>
      </div>
    </div>
  );
};

const CareersPage = () => {
  return (
    <div className={styles.container}>
      {/* Header */}
      <header className={styles.header}>
        <div className={styles.maxWidthWrapper}>
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <a href="https://syntra.io/" className="flex items-center gap-2">
                <img src={logo} alt="Syntra" className="h-12" />
              </a>
            </div>
            <nav className="flex items-center gap-8">
              <a href="https://security.syntra.io/" className={styles.link}>Security</a>
              <a href="/careers" className={styles.link}>Careers</a>
            </nav>
          </div>
        </div>
      </header>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
        {/* Hero Section */}
        <h1 className="text-5xl font-bold mb-12">
          <span className="text-white">Join us in building a safer software ecosystem</span>
        </h1>

        {/* Open Positions */}
        <div className="space-y-4">
          <div>
            <div className="flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h3 className="text-xl font-semibold text-white">Founding Engineer</h3>
                  <p className="text-gray-400 mt-1">New York, New York (hybrid)</p>
                </div>
                <a 
                  href="mailto:neil@syntra.io"
                  className="bg-emerald-600/90 text-white px-6 py-2 rounded-lg font-medium hover:bg-emerald-500/90 transition"
                >
                  Apply Now
                </a>
              </div>
              
              <div className="text-gray-300 space-y-4">
                <p>At Syntra, we're building infrastructure to make the software ecosystem safer.</p>
                
                <p>Software is at an inflection point:</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Teams are writing and using exponentially more code, especially because of AI</li>
                  <li>Regulatory requirements to secure that code - i.e. patch vulnerabilities - are tightening</li>
                  <li>This is causing teams to drown in security work that adds little customer value</li>
                </ul>

                <p>Most solutions on the market are alert tools: they scan for issues, finding issues that teams need to fix. We're taking a different approach by creating the first "dependency healer": a drop-in layer of infrastructure that does this patching and compliance work on behalf of every company.</p>

                <p>Our founders are former founding engineers at Vanta and developer platform leads at Dropbox, and we're working with a cohort of some of the most well-known tech enterprises. We've raised $4.9M from leading investors, and are bringing on early engineers to join our team.</p>

                <div>
                  <p className="font-semibold mb-2">As our founding engineer, you will:</p>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Design and build our core infrastructure for package analysis and automated patching</li>
                    <li>Create full-stack products that delight customers</li>
                    <li>Co-shape our company direction and hypotheses by talking to and learning from customers</li>
                    <li>Help build our engineering culture from the ground up</li>
                  </ul>
                </div>

                <div>
                  <p className="font-semibold mb-2">You might be a great fit if:</p>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>You have more than 2 years of experience</li>
                    <li>You enjoy working closely with customers and continually refining products until they love them</li>
                    <li>You value quality: our customers have been previously burned by a deluge of poor-quality products</li>
                    <li>You're excited about building high-availability infrastructure</li>
                    <li>You're willing to learn about the security industry</li>
                    <li>You have experience with Python or TypeScript (not a hard requirement; we care more about your interest in learning)</li>
                  </ul>
                </div>

                <div>
                  <p className="font-semibold mb-2">Our operating principles:</p>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Move with conviction: We believe in moving thoughtfully, but quickly. Figure out what data you need to make a call, and bias to action once you make it.</li>
                    <li>Debug your own thinking: We value extreme intentionality, clearly communicated. Our conclusions aren't always right, but we ensure we evaluate and continually improve them.</li>
                    <li>Spend innovation tokens wisely: Not everything needs to be reinvented. Break problems down to their binding constraint, learn from history, and focus energy where it matters most.</li>
                    <li>Operate with integrity: In our business, trust is everything. We build it through transparency, reliability, and doing the right thing for colleagues, customers, and the broader ecosystem.</li>
                  </ul>
                </div>

                <div>
                  <p className="font-semibold mb-2">Benefits:</p>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Health & Wellness: Premium health insurance, dental & vision coverage, mental health support</li>
                    <li>Financial: Meaningful equity, modern office and equipment, learning & development budget</li>
                    <li>Lifestyle: Flexible PTO, regular team events, team offsites</li>
                  </ul>
                </div>

                <p>Our customers are the builders of the future. They're increasingly diverse, and we won't be able to support them well unless our team reflects that diversity. Whether you're new to security, have taken an unconventional career path, or come from an underrepresented background, we want to hear from you.</p>

                <p>To apply, email neil@syntra.io with your resume and a brief note about why you're interested in joining us.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <div className={`${styles.maxWidthWrapper} p-12`}>
          <div className="flex justify-between items-start">
            <ul className="space-y-2">
              <li>
                <a href="#" className={styles.link}>Careers</a>
              </li>
              <li>
                <a href="mailto:contact@syntra.io" className={styles.link}>Contact</a>
              </li>
              <li>
                <a href="https://dev.syntra.io/privacy" className={styles.link}>Privacy</a>
              </li>
            </ul>
            <div className="flex items-center gap-8">
              <img 
                src={soc2Logo} 
                alt="SOC 2 Certified" 
                className="h-24 opacity-60" 
              />
              <img 
                src={iso27001Logo} 
                alt="ISO 27001 Certified" 
                className="h-24 opacity-60" 
              />
            </div>
          </div>
          <p className={`text-center text-gray-400 mt-6`}>
            &copy; 2025 Syntra. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

// WhitepaperDownload component
const WhitepaperDownload = () => {
  const [email, setEmail] = useState("");
  const [showEmailForm, setShowEmailForm] = useState(false);

  const handleSubmit = async () => {
    if (!email || !email.includes('@')) return;
    
    try {
      await axios.post(`${API_URL}/api/form-submit-1`, {
        email,
        timeline: 'immediate',
        language: 'whitepaper download',
        source: 'whitepaper_landing'
      });
      window.location.href = '/syntra_whitepaper.pdf';
    } catch (error) {
      // Handle error silently
    }
  };

  return (
    <div className={`mt-24 ${styles.cardLarge}`}>
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <div className={styles.iconWrapper}>
              <FileText className={styles.icon} />
            </div>
            <div>
              <h3 className={styles.heading}>Technical whitepaper</h3>
              <p className={styles.paragraph}>
                Learn how Syntra patches vulnerabilities without breaking changes
              </p>
            </div>
          </div>

          {!showEmailForm && (
            <Button
              onClick={() => setShowEmailForm(true)}
              className="bg-gradient-to-r from-emerald-400 to-emerald-500 text-[#0A1A35] px-8 py-4 rounded-xl font-medium hover:from-emerald-500 hover:to-emerald-400 transition-all duration-300 hover:translate-y-[-2px] shadow-lg shadow-emerald-500/10"
            >
              Download whitepaper
            </Button>
          )}
        </div>

        {showEmailForm && (
          <div className="flex flex-col gap-4 pt-2">
            <div className="h-[1px] w-full bg-gradient-to-r from-emerald-500/10 via-sky-500/10 to-transparent" />
            
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-1.5">
                <h4 className={`${colors.text.primary} font-medium`}>
                  Enter your work email to download the whitepaper
                </h4>
              </div>

              <div className="flex gap-3">
                <TextField className="flex-1">
                  <TextField.Input
                    placeholder="you@company.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="h-12 bg-white border-gray-300 text-gray-900 placeholder:text-gray-500 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 transition-colors"
                  />
                </TextField>

                <Button
                  onClick={handleSubmit}
              disabled={!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
              className={`${!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? 'opacity-50 cursor-not-allowed' : ''} bg-gradient-to-r from-emerald-400 to-emerald-500 text-[#0A1A35] px-8 py-4 rounded-xl font-medium hover:from-emerald-500 hover:to-emerald-400 transition-all duration-300 hover:translate-y-[-2px] shadow-lg shadow-emerald-500/10`}
                >
              Download whitepaper
                </Button>

                
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const StealthLandingPage = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.maxWidthWrapper}>
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              {/* Logo removed from here */}
            </div>
            <nav className="flex items-center gap-8">
              <a href="https://security.syntra.io/" className={styles.link}>Security</a>
            </nav>
          </div>
        </div>
      </header>

      <div className={styles.maxWidthWrapper}>
        <div className="min-h-[60vh] flex items-center justify-center">
          <img src={logo} alt="Syntra" className="h-24" />
        </div>
      </div>

      <footer className={styles.footer}>
        <div className={`${styles.maxWidthWrapper} p-12`}>
          <p className={`text-center text-gray-400`}>
            &copy; 2025 Syntra. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

const RevisedLandingPage = () => {
  // Replace the existing landing page with the stealth version
  return <StealthLandingPage />;
};

export { RevisedLandingPage };