import { createBrowserRouter, Navigate } from 'react-router-dom'
import { RevisedLandingPage } from './pages/RevisedLandingPage'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RevisedLandingPage />,
  },
  {
    path: '/careers',
    element: <RevisedLandingPage />,
  },
  {
    path: '/whitepaper',
    element: <Navigate to="/syntra_whitepaper.pdf" replace />,
  },
]) 