import React, { InputHTMLAttributes, forwardRef } from 'react';

interface TextFieldProps {
  children?: React.ReactNode;
  className?: string;
}

interface TextFieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const TextField = ({ children, className = '' }: TextFieldProps) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

const Input = forwardRef<HTMLInputElement, TextFieldInputProps>(
  ({ className = '', ...props }, ref) => {
    return (
      <input
        ref={ref}
        type="text"
        className={`w-full rounded-lg border bg-white px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm sm:leading-6 ${className}`}
        {...props}
      />
    );
  }
);

Input.displayName = 'TextFieldInput';

TextField.Input = Input;

export { TextField }; 